var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-3",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"btn",attrs:{"color":"secondary--text primary","large":""},on:{"click":function($event){return _vm.openAddDialog()}}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v("add_circle_outline")]),_c('h3',[_vm._v("إضافة "+_vm._s(this.$route.meta.single))])],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:500ms",value:(_vm.getItems),expression:"getItems",arg:"500ms"}],attrs:{"label":"ابحث هنا","hide-details":"","prepend-inner-icon":"search","dense":"","outlined":"","filled":""},model:{value:(_vm.$global.state.filter.search),callback:function ($$v) {_vm.$set(_vm.$global.state.filter, "search", $$v)},expression:"$global.state.filter.search"}})],1)],1),_c('v-card',{attrs:{"id":"card"}},[_c('v-data-table',{staticClass:"mytable",attrs:{"items":_vm.$global.state.offers,"loading":_vm.$global.state.loading,"headers":_vm.headers,"search":_vm.$global.state.filter.search,"hide-default-footer":"","loading-text":"جاري تحميل البيانات","no-data-text":"لا توجد بيانات","id":_vm.$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light',"items-per-page":_vm.$global.state.filter.pageSize},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$global.state.offers.indexOf(item) + 1))])]}},{key:"item.offerValue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"indigo--text"},[_vm._v(_vm._s(item.offerValue)+" %")])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user == '' || item.user == null)?_c('span',{staticClass:"red--text"},[_vm._v("عام")]):_c('span',[_c('span',{staticClass:"green--text font-weight-bold"},[_vm._v("خاص بـ")]),_vm._v(" "+_vm._s(item.user.fullName)+" ")])]}},{key:"item.expiryDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(new Date(item.expiryDate).toISOString().substring(0,10)))]),_c('v-chip',{staticClass:"mb-1",attrs:{"small":"","color":item.expiryDate > _vm.nowDate ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.expiryDate > _vm.nowDate ? 'فعال' : 'غير فعال')+" ")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(new Date(item.created).toISOString().substring(0,10)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticStyle:{"width":"75px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item.id, index)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("حذف")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openEditDialog(item)}}},on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("تعديل")])])],1)]}}])})],1),_c('div',{staticClass:"d-flex justify-space-between py-3"},[_c('v-spacer'),_c('Pagination')],1),_c('Dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }