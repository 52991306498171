<template>
    <div>
        <v-row align="center" class="mb-3">
            <v-col cols="12" md="2">
                <v-btn @click="openAddDialog()" color="secondary--text primary" large class="btn">
                    <v-icon class="ml-2">add_circle_outline</v-icon>
                    <h3>إضافة {{this.$route.meta.single}}</h3>
                </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="4">
                <v-text-field
                    v-debounce:500ms="getItems"
                    v-model="$global.state.filter.search"
                    label="ابحث هنا"
                    hide-details
                    prepend-inner-icon="search"
                    dense
                    outlined
                    filled
                />
            </v-col>
        </v-row>
        <v-card id="card">
            <v-data-table
                class="mytable"
                :items="$global.state.offers"
                :loading="$global.state.loading"
                :headers="headers"
                :search="$global.state.filter.search"
                hide-default-footer
                loading-text="جاري تحميل البيانات"
                no-data-text="لا توجد بيانات"
                :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
                :items-per-page="$global.state.filter.pageSize">
                
                <template v-slot:item.id="{ item }">
                    <span>{{ $global.state.offers.indexOf(item) + 1 }}</span>
                </template>

                <template v-slot:item.offerValue="{item}">
                    <span class="indigo--text">{{ item.offerValue }} %</span>
                </template>

                <template v-slot:item.user="{item}">
                    <span class="red--text" v-if="item.user == '' || item.user == null">عام</span>
                    <span v-else>
                        <span class="green--text font-weight-bold">خاص بـ</span>
                        {{item.user.fullName}}
                    </span>
                </template>

                <template v-slot:item.expiryDate="{ item }">
                    <div>{{new Date(item.expiryDate).toISOString().substring(0,10)}}</div>
                    <v-chip class="mb-1" small :color="item.expiryDate > nowDate ? 'green' : 'red'"> {{ item.expiryDate > nowDate ? 'فعال' : 'غير فعال'}} </v-chip>
                </template>

                <template v-slot:item.created="{ item }">
                    <div>{{new Date(item.created).toISOString().substring(0,10)}}</div>
                </template>

                <template v-slot:item.actions="{item, index}">
                    <div style="width: 75px">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn @click.stop="deleteItem(item.id, index,)" v-on="on" icon>
                                    <v-icon color="error">delete</v-icon>
                                </v-btn>
                            </template>
                            <span>حذف</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn @click.stop="openEditDialog(item)" v-on="on" icon>
                                    <v-icon color="info">edit</v-icon>
                                </v-btn>
                            </template>
                            <span>تعديل</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>
        <div class="d-flex justify-space-between py-3">
            <v-spacer></v-spacer>
            <Pagination />
        </div>
        <Dialog />
    </div>
</template>

<script>
export default {
    components: {
        Dialog: () => import("./dialog"),
    },
    data() {
        return {
            headers: [
                {
                    text: "#",
                    value: "id",
                },
                {
                    text: "عنوان",
                    value: "titel",
                },
                {
                    text: "قيمة العرض",
                    value: "offerValue",
                },
                {
                    text: "تاريخ انتهاء العرض",
                    value: "expiryDate",
                },
                {
                    text: "المنتج",
                    value: "product.name",
                },
                {
                    text: "المنتج",
                    value: "product.name",
                },
                {
                    text: "نوع العرض",
                    value: "user",
                },
                {
                    text: "تاريخ الإضافة",
                    value: "created",
                },
                {
                    text: "الإجراءات",
                    value: "actions",
                    sortable: false,
                    align: "center",
                },
            ],
            nowDate: new Date().toISOString(),
        };
    },

    mounted() {
        this.getItems();
        this.$eventBus.$on(`refresh`, () => {
            this.getItems();
        });
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
    },

    methods: {
        getItems() {
            this.$global.dispatch(
                `get${this.$route.meta.endPoint}`,
                this.$route.meta.role
            );
        },

        async deleteItem(id, i) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            // this.$genericService.swalAlertSuccess();
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.$global.state.offers.splice(i, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: "add",
            };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },

        openEditDialog(item) {
            item.dialogType = "edit";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
            this.$eventBus.$emit("fill-fields");
        },
    },
};
</script>
